<template>
  <Portlet 
    title="Visualisation"
    class="spotVisualisation"
    icon="eye"
  >
    <!-- SVG -->
    <svg 
      viewBox="0 0 300 400" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- DEFINITIONS -->
      <SpotDefinitions :spot="spot" />
      <!-- IMAGE STARTS HERE -->
      <g>
        <!-- BORDERS -->
        <use 
          v-if="spot.border" 
          xlink:href="#originalBorder" 
          transform="scale(1, 1) translate(0, 0)"
        />
        <!-- STARTING StartScene -->
        <g v-if="isStartScene('tunnel')">
          <!-- startScene for one -->
          <use 
            v-if="onePerson(spot.people)" 
            xlink:href="#originalHouse2" 
            transform="scale(1, 1) translate(0, 0)"
          />
          <!-- startScene for two -->
          <use 
            v-if="twoPersons(spot.people)" 
            xlink:href="#originalHouse2" 
            transform="scale(2, 1) translate(-75, 0)"
          />
          <!-- startScene for three -->
          <use 
            v-if="threePersons(spot.people)" 
            xlink:href="#originalHouse2" 
            transform="scale(3, 1) translate(-100, 0)"
          />
          <!-- startScene for four -->
          <use 
            v-if="fourOrMorePersons(spot.people)" 
            xlink:href="#originalHouse2" 
            transform="scale(4, 1) translate(-112.5, 0)"
          />
        </g>
        <!-- BMW -->
        <g v-if="isStartScene('bmw')">
          <!-- startScene for one -->
          <use 
            v-if="onePerson(spot.people)" 
            xlink:href="#originalHouse1" 
            transform="scale(1, 1) translate(80, -10)"
          />
          <!-- startScene for two -->
          <use 
            v-if="twoPersons(spot.people)" 
            xlink:href="#originalHouse1" 
            transform="scale(1.25, 1) translate(40, -10)"
          />
          <!-- startScene for three -->
          <use 
            v-if="threePersons(spot.people)" 
            xlink:href="#originalHouse1" 
            transform="scale(1.5, 1) translate(20, -10)"
          />
          <!-- startScene for four -->
          <use 
            v-if="fourOreMorePersons(spot.people)" 
            xlink:href="#originalHouse1" 
            transform="scale(1.75, 1) translate(0, -10)"
          />
        </g>
        <!-- STARTING RFID -->
        <g v-if="validRFID">
          <!-- RFID for four -->
          <use 
            v-if="fourOreMovrePersons(spot.people)" 
            xlink:href="#originalRFID" 
            transform="scale(1, 1) translate(0, 0)"
          />
          <!-- RFID for two -->
          <use 
            v-if="twoOreMorePersons(spot.people)" 
            xlink:href="#originalRFID" 
            transform="scale(1, 1) translate(37.5, 0)"
          />
          <!-- RFID for one -->
          <use 
            v-if="oneOrMorePersons(spot.people)" 
            xlink:href="#originalRFID" 
            transform="scale(1, 1) translate(75, 0)"
          />
          <!-- RFID for three -->
          <use 
            v-if="threeOrMorePersons(spot.people)" 
            xlink:href="#originalRFID" 
            transform="scale(1, 1) translate(112.5, 0)"
          />
        </g>
        <!-- PEOPLE -->
        <g>
          <!-- four people -->
          <use 
            v-if="fourOrMorePersons(spot.people)" 
            xlink:href="#originalPerson" 
            transform="scale(1, 1) translate(0, 0)"
          />
          <!-- two people -->
          <use 
            v-if="twoOrMorePersons(spot.people)" 
            xlink:href="#originalPerson" 
            transform="scale(1, 1) translate(37.5, 0)"
          />
          <!-- one person -->
          <use 
            v-if="oneOrMorePersons(spot.people)" 
            xlink:href="#originalPerson" 
            transform="scale(1, 1) translate(75, 0)"
          />
          <!-- two people -->
          <use 
            v-if="threeOrMorePersons(spot.people)" 
            xlink:href="#originalPerson" 
            transform="scale(1, 1) translate(112.5, 0)"
          />
        </g>
        <!-- SLOPES -->
        <g v-if="oneOrMorePersons(spot.slopes)">
          <!-- one slope -->
          <use 
            v-if="onePerson(spot.slopes)" 
            xlink:href="#originalSlope" 
            transform="scale(1, 1) translate(0, 0)"
          />
          <!-- two slopes -->
          <use 
            v-if="twoOrMorePersons(spot.slopes)" 
            xlink:href="#originalSlope" 
            transform="scale(1, 1) translate(-33.3, 0)"
          />
          <use 
            v-if="twoOrMorePersons(spot.slopes)" 
            xlink:href="#originalSlope" 
            transform="scale(1, 1) translate(33.3, 0)"
          />
        </g>
        <!-- FINISH LINE (AREA) -->
        <g v-if="spot.jump">
          <use 
            xlink:href="#originalBag" 
            transform="scale(1, 1) translate(0, 0)"
          />
        </g>
        <!-- FINISH LINE (GOAL)  -->
        <g v-if="spot.goal">
          <use 
            xlink:href="#originalGoal" 
            transform="scale(1, 1) translate(0, 0)"
          />
        </g>

        <!-- CAMERAS AND POLES -->
        <g>
          <!-- first pole -->
          <g v-if="oneOrMoreCameras(spot.cameraOne)">
            <!-- one camera -->
            <use 
              v-if="oneOrMoreCameras(spot.cameraOne)" 
              xlink:href="#originalCamera" 
              transform="scale(1, 1) translate(0, 0)"
            />
            <!-- two cameras -->
            <use 
              v-if="twoOrMoreCameras(spot.cameraOne)" 
              xlink:href="#originalCamera" 
              transform="scale(1, 1) translate(0, 18)"
            />
          </g>
          <!-- pole two -->
          <g v-if="oneOrMoreCameras(spot.cameraTwo)">
            <!-- one camera -->
            <use 
              v-if="oneOrMoreCameras(spot.cameraTwo)" 
              xlink:href="#originalCamera" 
              transform="scale(1, 1) translate(0, 60)"
            />
            <!-- two cameras -->
            <use 
              v-if="twoOrMoreCameras(spot.cameraTwo)" 
              xlink:href="#originalCamera" 
              transform="scale(1, 1) translate(0, 78)"
            />
          </g>
          <!-- pole three -->
          <g v-if="oneOrMoreCameras(spot.cameraThree)">
            <!-- one camera -->
            <use 
              v-if="oneOrMoreCameras(spot.cameraThree)" 
              xlink:href="#originalCamera" 
              transform="scale(1, 1) translate(0, 120)"
            />
            <!-- two cameras -->
            <use 
              v-if="twoOrMoreCameras(spot.cameraThree)" 
              xlink:href="#originalCamera" 
              transform="scale(1, 1) translate(0, 138)"
            />
          </g>
        </g>
      </g>
    </svg>
  </Portlet>
</template>

<script>
export default {
  name: "SpotVisualisation",
  components: {
    SpotDefinitions: () => import('@/components/SpotVisualisation/SpotDefinitions.vue')
  },
  props: {
    spot: {
      type: Object,
      required: true
    }
  },
  computed: {
    validRFID: function () {
      let acceptable = [ 'Skidata Freemotion Gate', 'Skidata Easygate', 'Team Axess AX500 Flap Gate', 'Skiline Checkpoint' ];
      if ( acceptable.indexOf(this.spot.rFID) > -1 ) return true;
      return false;
    }
  },
  methods: {
    onePerson (count) {
      if (count == 1) return true;
      return false;
    },
    twoPersons (count) {
      if (count == 2) return true;
      return false;
    },
    threePersons (count) {
      if (count == 3) return true;
      return false;
    },
    fourPersons (count) {
      if (count == 4) return true;
      return false;
    },
    oneOreMorePersons (count) {
      if (count >= 1) return true;
      return false;
    },
    twoOreMorePersons (count) {
      if (count >= 2) return true;
      return false;
    },
    threeOreMorePersons (count) {
      if (count >= 3) return true;
      return false;
    },
    fourOreMorePersons (count) {
      if (count >= 4) return true;
      return false;
    },
    oneOrMoreCameras (count) {
      if (count >= 1) return true;
      return false;
    },
    twoOrMoreCameras (count) {
      if (count >= 2) return true;
      return false;
    },
    threeOrMoreCameras (count) {
      if (count >= 3) return true;
      return false;
    },
    isStartScene (scenetype) {
      if (this.spot.startScene.toLowerCase().includes(scenetype.toLowerCase())) return true;
      return false;
    }
  }
}
</script>

<style>
svg {
  width: 100%;
}
</style>
